import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    redirect: '/',
    default: true,
    meta: {},
    children: [
      {
        path: '',
        component: () => import('@/views/home/home.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'home',
        component: () => import('@/views/home/home.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'home_en',
        component: () => import('@/views/home/home.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit',
        component: () => import('@/views/recruit/index.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_en',
        component: () => import('@/views/recruit/index.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_campus_en',
        component: () => import('@/views/recruit/campus.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_internship_en',
        component: () => import('@/views/recruit/internship.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_suggestion_en',
        component: () => import('@/views/recruit/suggestion.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_treatment_en',
        component: () => import('@/views/recruit/treatment.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_social_en',
        component: () => import('@/views/recruit/social.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_campus',
        component: () => import('@/views/recruit/campus.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_internship',
        component: () => import('@/views/recruit/internship.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_suggestion',
        component: () => import('@/views/recruit/suggestion.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_treatment',
        component: () => import('@/views/recruit/treatment.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'recruit_social',
        component: () => import('@/views/recruit/social.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'contact',
        component: () => import('@/views/about/contact.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'ricohGroup',
        component: () => import('@/views/about/ricohGroup.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'researchCenter',
        component: () => import('@/views/about/researchCenter.vue'),
        meta: {
          isactive: '1'
        }
      },
      
      {
        path: 'ricohGroup_en',
        component: () => import('@/views/about/ricohGroup.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'researchCenter_en',
        component: () => import('@/views/about/researchCenter.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'socialResponsibility',
        component: () => import('@/views/about/socialResponsibility.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'tech',
        component: () => import('@/views/tech/index.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'tech_en',
        component: () => import('@/views/tech/index_en.vue'),
        meta: {
          isactive: '1'
        }
      },
      {
        path: 'patent',
        component: () => import('@/views/patent/index.vue'),
      },
      // FAQ
      {
        path: 'faq',
        component: () => import('@/views/faq/index.vue'),
      },
      {
        path: 'faq_en',
        component: () => import('@/views/faq/index.vue'),
      },
      // 国内合作与开放创新
      {
        path: 'openInnovation',
        component: () => import('@/views/openInnovation/index.vue'),
      },
      // 我们的使命
      {
        path: 'ourMission',
        component: () => import('@/views/ourMission/index.vue'),
      },
      {
        path: 'ourMission_en',
        component: () => import('@/views/ourMission/index.vue'),
      },
      // 理光模式
      {
        path: 'way',
        component: () => import('@/views/way/index.vue'),
      },
      // 智慧医疗解决方案
      {
        path: 'smartHealthcare',
        component: () => import('@/views/smartHealthcare/index.vue'),
      },
      {
        path: 'smartHealthcare_en',
        component: () => import('@/views/smartHealthcare/index.vue'),
      },
      // 智慧办公解决方案
      {
        path: 'smartOffice',
        component: () => import('@/views/smartOffice/index.vue'),
      },
      {
        path: 'smartOffice_en',
        component: () => import('@/views/smartOffice/index.vue'),
      },
      // 智慧工厂解决方案
      {
        path: 'smartFactory',
        component: () => import('@/views/smartFactory/index.vue'),
      },
      // 智慧工厂解决方案
      {
        path: 'smartCarFactory',
        component: () => import('@/views/smartCarFactory/index.vue'),
      },
      // 智慧工厂解决方案
      {
        path: 'smartFactory_en',
        component: () => import('@/views/smartFactory/index.vue'),
      },
      // 智慧工厂解决方案
      {
        path: 'smartCarFactory_en',
        component: () => import('@/views/smartCarFactory/index.vue'),
      },
      // 新闻中心
      {
        path: 'news',
        component: () => import('@/views/newsCenter/index.vue'),
      },
      // 隐私
      {
        path: 'privacy',
        component: () => import('@/views/about/privacy.vue'),
      },
      // 条款
      {
        path: 'clause',
        component: () => import('@/views/about/clause.vue'),
      },
      {
        path: 'news_20250108',
        component: () => import('@/views/newsCenter/details/2025/20250108.vue'),
      },
      {
        path: 'news_2023711',
        component: () => import('@/views/newsCenter/details/2023/2023711.vue'),
      },
      {
        path: 'news_20231110',
        component: () => import('@/views/newsCenter/details/2023/20231110.vue'),
      },
      {
        path: 'news_20241025',
        component: () => import('@/views/newsCenter/details/2024/20241025.vue'),
      },
      {
        path: 'news_20241017',
        component: () => import('@/views/newsCenter/details/2024/20241017.vue'),
      },
      {
        path: 'news_20241016',
        component: () => import('@/views/newsCenter/details/2024/20241016.vue'),
      },
      {
        path: 'news_20240109',
        component: () => import('@/views/newsCenter/details/2024/20240109.vue'),
      },
      {
        path: 'news_20240112',
        component: () => import('@/views/newsCenter/details/2024/20240112.vue'),
      }
      ,
      {
        path: 'news_20240813',
        component: () => import('@/views/newsCenter/details/2024/20240813.vue'),
      }
      ,
      {
        path: 'news_20240823',
        component: () => import('@/views/newsCenter/details/2024/20240823.vue'),
      }
      ,
      {
        path: 'news_20240829',
        component: () => import('@/views/newsCenter/details/2024/20240829.vue'),
      }
      ,
      {
        path: 'news_20240906',
        component: () => import('@/views/newsCenter/details/2024/20240906.vue'),
      }
      ,
      {
        path: 'news_20240524',
        component: () => import('@/views/newsCenter/details/2024/20240524.vue'),
      },
      {
        path: 'news_20240606',
        component: () => import('@/views/newsCenter/details/2024/20240606.vue'),
      },
      {
        path: 'news_20230407',
        component: () => import('@/views/newsCenter/details/2023/20230407.vue'),
      }
      ,
      {
        path: 'news_2023210',
        component: () => import('@/views/newsCenter/details/2023/20230210.vue'),
      },
      {
        path: 'news_20230111_1',
        component: () => import('@/views/newsCenter/details/2023/20230111_1.vue'),
      },
      {
        path: 'news_20230111_2',
        component: () => import('@/views/newsCenter/details/2023/20230111_2.vue'),
      },
      {
        path: 'news_20220617',
        component: () => import('@/views/newsCenter/details/2022/20220617.vue'),
      },
      {
        path: 'news_20220715',
        component: () => import('@/views/newsCenter/details/2022/20220715.vue'),
      },
      {
        path: 'news_20220729_1',
        component: () => import('@/views/newsCenter/details/2022/20220729_1.vue'),
      },
      {
        path: 'news_20220729_2',
        component: () => import('@/views/newsCenter/details/2022/20220729_2.vue'),
      },
      {
        path: 'news_20220831_1',
        component: () => import('@/views/newsCenter/details/2022/20220831_1.vue'),
      },
      {
        path: 'news_20220831_2',
        component: () => import('@/views/newsCenter/details/2022/20220831_2.vue'),
      },
      {
        path: 'news_20220909_1',
        component: () => import('@/views/newsCenter/details/2022/20220909_1.vue'),
      },
      {
        path: 'news_20220909_2',
        component: () => import('@/views/newsCenter/details/2022/20220909_2.vue'),
      },
      {
        path: 'news_20220909_3',
        component: () => import('@/views/newsCenter/details/2022/20220909_3.vue'),
      },
      {
        path: 'news_20220909_4',
        component: () => import('@/views/newsCenter/details/2022/20220909_4.vue'),
      },
      {
        path: 'news_20221031',
        component: () => import('@/views/newsCenter/details/2022/20221031.vue'),
      },
      {
        path: 'news_20221209',
        component: () => import('@/views/newsCenter/details/2022/20221209.vue'),
      }
        ]
  },
]

const router = new VueRouter({
  mode: 'history', 
  routes
})

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})

export default router
